@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}

.main-artical {
    overflow-x: hidden;
}

// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     overflow-x: hidden;
// }

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    background: var(--white);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    cursor: pointer;
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
}

p {
    font-size: 15px;
    font-family: var(--regular);
    color: var(--themeColor);
    // padding-bottom: 15px;
    // background: var(--themeColor);
    // -webkit-text-fill-color: transparent;
    // -webkit-background-clip: text;
}

*::-moz-selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--themeColor) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

.themeColor {
    color: var(--themeColor);
}

.darkColor {
    color: var(--darkColor);
}


.whiteColor {
    color: var(--white);
}

.goldGradientTxt {
    background: var(--goldGradient);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}

.lightGradient {
    background: var(--lightGradient);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.bg-white {
    background: var(--white);
}

.bg-themeColor {
    background: var(--themeColor);
}

.bg-darkColor {
    background: var(--darkColor);
}

/* header-btn */
.header-btn {
    overflow: hidden;
}

.header-btn::after {
    height: 100%;
    left: -35% !important;
    top: 0 !important;
    transform: skew(50deg);
    transition-duration: 0.6s !important;
    transform-origin: top left;
    width: 0;
}

.header-btn:hover:after {
    height: 100%;
    width: 135%;
}

.common-space {
    padding-top: 50px;
    padding-bottom: 50px;
}

.common-space-over {
    margin-top: 100px;
    margin-bottom: 100px;
}

.logoAr{
    display: none;
}
.logoEn{
    display: block;
}












.about-bg {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.service-bg {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom;
}

.achieve-bg {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .lazy-load-image-background {
        position: relative !important;
        width: 100% !important;
    }
}

.chosse-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0 120px;
    position: relative;
}

.slider-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0 80px;
    position: relative;

    & .border-image>span {
        width: 100%;
        position: absolute;
        bottom: -168px;
        z-index: 1;
    }
}

.gallery-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding: 120px 0 0;
    position: relative;

    // & #gallery {
    //     padding: 120px 0 0;
    // }
}

.client-bg {
    padding: 80px 0;
}

.contact-bg {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.border-bottom-line {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 92%;
        height: 1%;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        // background-color: var(--darkColor);
        border: 0.5px solid #79b5ce7d;
    }
}

.border-image {
    position: relative;

    &>span {
        width: 100%;
        position: absolute;
        bottom: -65px;
        z-index: 1;

        &>img {
            width: 100%;

        }
    }
}
.themePattern{
    position: absolute !important;
}

.border-top-line {
    position: relative;
    padding: 25px 0 25px;

    &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 1%;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        border: 0.5px solid rgba(121, 181, 206, 0.4901960784);
    }
}
.banner-sub-heading{
    font-size: 35px !important;
    line-height: 45px !important;
}
.social-link {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 30px;
    margin: auto;
    display: flex;
    gap: 15px;
    z-index: 1000;
    height: 200px;
    flex-wrap: wrap;
    width: 60px;

    & svg {
        cursor: pointer;
    }
}

.Banner-board {
    position: relative;
    right: 50px;
    top: 30px;
    z-index: 2;

    &>div {
        width: 178px;
        height: 162px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &>button {
            position: relative;
            left: 14px;
            top: 10px;
            width: 150px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


}



/* Scrollbar Styling */
.sale-content-scroll::-webkit-scrollbar {
    width: 6px;
}

.sale-content-scroll::-webkit-scrollbar-track {
    position: relative;
    background-color: #ffe6a864;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
}

.sale-content-scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--DarkText);

    &::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 10px;
        background-color: var(--DarkText);
    }
}

iframe {
    margin-top: 35px;
    width: 100%;
    height: 420px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}


.message {
    position: fixed !important;
    top: 50%;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto !important;
}

.video-react .video-react-video,
.video-react-controls-enabled,
.video-react-poster {
    border-radius: 50px 0 50px 0 !important;

}

.video-react-poster {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}

.video-react .video-react-big-play-button {
    border: 1px solid var(--white) !important;
    background-color: #ffffff4d !important;
    font-size: 55px !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 85px !important;
    width: 85px !important;
    border-radius: 50% !important;
    transition: all 0.4s;
}

video-react-controls-enabled .loading-btn {
    font-size: 18px;
    font-family: var(--semiBold);
    color: var(--white);
}


.spinner {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 3px solid;
    border-color: var(--themeColor) transparent;
    animation: spin 1s infinite ease-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.star-1 {
    position: absolute;
    width: 550px;
    left: 410px;
}

.video-react-poster {
    width: 100%;
    height: 350px !important;
}

video {
    width: 100%;
    height: 350px !important;
}

.video-react.video-react-fluid {
    height: 350px !important;
    padding: unset !important;
}
.dgts span{
    font-size: 15px !important;
}
textarea.input-field{
    height: 140px !important;
}
.prTcList{
    font-size: 16px;
    font-family: var(--regular);
    list-style-type: disc;
    padding-left: 40px;
    margin-bottom: 25px;
    li{
        list-style-type: disc;
        
    }
}
.privacy-terms-container{
    padding-bottom: 50px;
}
/***************** RTL DIrection*************************/

html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body.rtl {
    position: relative !important;
    font-family: var(--arRegular);
    font-style: normal;
    font-size: 15px;

    .logoAr{
        display: block;
    }
    .logoEn{
        display: none;
    }
}



.rtl .about-house,
.rtl .house-bird,
.rtl .click-btn>svg,
.rtl .service-boy,
.rtl .slick-slider svg,
.rtl .contact-svg,
.rtl .bach-img img,
.rtl .about-vector,
.rtl .trainer-wrapper:after,
.rtl .trainer-img img,
.rtl .star-right,
.rtl .star-left,
.rtl .contact-vector-1,
.rtl .contact-vector-2,
.rtl .btn-text svg {
    transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    -moz-transform: scaleX(-1) !important;
    -ms-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
}


.rtl .nav-bar-list a,
.rtl .footer-list a,
.rtl .field-box label {
    // font-family: var(--arBold) !important;
}
.rtl .aboutText,
.rtl .FeaturesText,
.rtl .featureCardText,
.rtl .howitWorksPointTxt,
.rtl .contactText,
.rtl .answerText,
.rtl .input-field,
body.rtl .privacyContainer > span {
    font-family: var(--arLight) !important;
}
.rtl .main-heading,
.rtl .banner-sub-heading,
.rtl .bannerCategories .bannerCategoryThumb span,
.rtl .aboutHglText,
.rtl .accordion-button,
.rtl .field-box label,
.rtl .custom-btn span,
.rtl .footer-revised span:not(.dgts),
.rtl .footer-list a,
.rtl .prTcTitle{
    font-family: var(--arRegular) !important;
}
// .rtl .nav-bar-list a.active {
//     font-family: var(--arBold) !important;
// }


.rtl .header-btn span,
.rtl .choice-content,
.rtl .contact-text,
.rtl .react-images__positioner {
    direction: ltr;
}
.rtl .change-Btn {
    svg{
        margin-left: 0 !important;
        margin-right: 6px;
    }
}
.rtl p{
    font-size: 32px !important;
}
.rtl .main-heading{
    line-height: 60px !important;
    font-size: 80px !important;
}
.rtl .bannerCategories .bannerCategoryThumb span {
    font-size: 29px !important;
    line-height: 26px !important;
}
.rtl .featuresTitleHeading{
    line-height: 40px !important;
    font-size: 48px !important;
}
.rtl .howitWorksPointTitle {
    line-height: 40px !important;
    font-size: 48px !important;
}
.rtl .accordion-item .accordion-button {
    font-size: 40px !important;
    line-height: 35px !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
body.rtl .privacyContainer > span {
    font-size: 28px !important;
    line-height: 32px !important;
}
.rtl .accordion-button{
    text-align: right;
}
.rtl .followHeading{
    font-size: 40px !important;
    line-height: 35px !important;
}
.rtl .field-box label{
    font-size: 32px !important;
    line-height: 30px !important;
}
.rtl .input-field, .rtl .file-attached {
    font-family: var(--arLight) !important;
    font-size: 27px !important;
    line-height: 27px !important;
    padding: 5px 15px !important;
    height: 47px;
}
.rtl .footer-list a {
    font-size: 28px !important;
    line-height: 25px !important;
    font-family: var(--arLight) !important;
}
.rtl .footer-revised{
    font-size: 28px !important;
    line-height: 25px !important;
}
.rtl .footer-revised span:not(.dgts){
    font-size: 28px !important;
    line-height: 25px !important;
}
.rtl .prTcTitle{
    font-size: 65px !important;
    line-height: 60px !important;
}
.rtl .error-message{
    font-family: var(--arRegular) !important;
    /* border: 1px solid #ff00004f; */
    color: #d40404 !important;
    /* padding: 6px 8px; */
    font-size: 23px !important;
    line-height: 25px;
    margin-bottom: 0;
    /* background: #ff00004f; */
    /* -webkit-text-fill-color: white; */
}
.rtl button.contactBtn span {
    font-size: 36px !important;
    line-height: 36px !important;
}
/* .rtl .contact-info > div:last-child .contact-text{
    font-family: var(--arLight) !important;
    font-size: 26px !important;
} */
.rtl .prTcList{
    font-size: 28px !important;
    line-height: 32px !important;
    padding-left: 0px;
    padding-right: 40px;
    font-family: var(--arLight);
}
// .rtl .footer-list a.prTcLink{
//     font-size: 28px !important;
//     line-height: 25px !important;
//     font-family: var(--arLight) !important;
// }
.rtl .privacySubH{
    font-family: var(--arBold) !important;
    font-size: 40px !important;
    line-height: 50px !important;
}
.engWd{
    font-family: var(--regular);
    font-size: 16px;
}
/* .rtl .bannerPattern1 {
    left: -60px;
    top: 160px;
    width: 130px;
} */
.rtl .banner-content {
    position: relative;
    left: auto;
    right: 100px;
    bottom: 100px;
}
.rtl .banner-sub-heading{
    font-size: 55px !important;
    line-height: 45px !important;
}
.rtl .aboutHglText {
    font-size: 40px !important;
    font-family: var(--arRegular) !important;
}
.rtl .howitWorksPoint {
    padding-left: 0 !important;
    padding-right: 83px !important;
}
.rtl .howitWorksPoint .howitWorksPointCount {
    left: unset !important;
    right: 0 !important;
}
.rtl .howitWorksPoint::after {
    left: unset !important;
    right: 23px !important;
}
.rtl .howitWorksPoint::before {
    left: unset !important;
    right: 23px !important;
}
.rtl .accordion-button::after {
    margin-left: unset !important;
    margin-right: auto !important;
}
// .rtl .contact-info{
//     font-family: var(--arRegular) !important;
// }
.rtl .Banner-board {
    position: relative;
    left: auto;
    right: 0px;
    top: 25px;

    &>div {
        &>button {

            left: auto;
            right: 14px;
        }
    }
}
.rtl .field-box input::placeholder,
.rtl textarea::placeholder {
    font-family: var(--arRegular);
}

.rtl .loading-btn {
    font-family: var(--arRegular) !important;
}

.rtl .Toastify__toast-body>div {
    font-family: var(--arRegular) !important;
    font-size: 30px !important;
}

.rtl{
    .bannerPattern1 {
        left: unset !important;
        right: -60px !important;
    }
    .bannerPattern2{
        left: 0 !important;
        right: unset !important;
    }
    .aboutPattern1 {
        right: unset !important;
        left: -112px;
    }
    .aboutPattern2{
        left: unset !important;
        right: -45px !important;
    }
    .contactPattern1 {
        left: unset !important;
        right: -116px !important;
    }
    .contactPattern2 {
        right: unset !important;
        left: -113px !important;
    }
}