@media (max-height: 700px) {
  .bannerImg img{
    width: 450px;
  }
}
@media (max-height: 650px) {
  .bannerImg img{
    width: 420px;
  }
}
@media (max-height: 600px) {
  .bannerImg img{
    width: 400px;
  }
}


@media (max-width: 1399px) {
	.nav-bar .nav-bar-list {
		gap: 1px !important;
	}
	.nav-bar .nav-bar-list a {
		padding: 13px 18px !important;
	}
	.featuresColContent {
		padding: 40px 25px 270px 25px !important;
	}
}

@media (max-width: 1199px) {
	.logo svg {
		width: 200px !important;
	}
	.bannerWrapper{
		max-height: 650px !important;
		min-height: 650px !important;
	}
	.nav-bar .nav-bar-list a {
		padding: 10px 12px !important;
	}
	.appPlatforms {
		gap: 3px !important;
		margin-top: 10px !important;
	}
	.bannerCategories {
		gap: 15px !important;
		margin-top: 20px !important;
	}
	.bannerMainHeading {
		margin-bottom: 5px !important;
	}
	.featuresColContent {
		padding: 30px 15px 230px 15px !important;
	}
}

@media (max-width: 991px) {
	.bannerWrapper {
		max-height: unset !important;
		min-height: unset !important;
		height: unset !important;
		padding: 50px 0 25px 0;
	}
	.bannerImgWrapper{
		display: none;
	}
	.container .bannerContentWrap {
		padding-right: calc(var(--bs-gutter-x) * .5) !important;
	}

	.nav-bar-list {
		display: none !important;
	}

	.toggleBtn {
		margin: 0 5px;
		display: block !important;
		cursor: pointer;
		transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
	}

	.toggleBtn svg {
		color: var(--darkGradient);
	}

	.showToggle {
		position: absolute !important;
		top: 66px;
		left: 0;
		background: var(--white) !important;
		transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
		color: var(--white) !important;
		width: 100% !important;
		display: block !important;
		border-top: 1px solid var(--grey);
		border-bottom: 1px solid var(--grey3);
		box-shadow: 0px 5px 15px #dbdbdbd6;
	}

	.showToggle > div {
		border-bottom: 1px solid var(--white);
		outline: none;
		width: 100%;
		padding: 0px;
	}
	.nav-bar .nav-bar-list a{
		display: block;
		border-radius: 0 !important;
	}

	.showToggle a:hover {
		color: var(--themeColor) !important;
		-webkit-text-fill-color: var(--white) !important;
	}

	.showToggle .active {
		color: var(--themeColor) !important;
		-webkit-text-fill-color: var(--white) !important;
		background: var;
	}

	.showToggle .nav-bar-list a {
		// color: var(--themeColor);
		background: none !important;

	}

	.nav-bar-list a.active::after,
	.nav-bar-list a.active::before,
	.nav-bar-list a:hover::after,
	.nav-bar-list a:hover::before {
		background: none !important;
	}

	.sticky.py-2 {
		transition: all 0.4s ease-in-out;
		padding-top: 0.2rem !important;
		padding-bottom: 0.2rem !important;
	}
	.themePattern{
		display: none !important;
	}
	.aboutImgWrapper{
		display: none;
	}
	.aboutWrapper{
		padding: 50px 0 50px 0 !important;
	}
	.featuresBg {
		padding-top: 50px !important;
		padding-bottom: 120px !important;
	}
	.featuresContentWrap {
		padding: 0 calc(var(--bs-gutter-x) * .5) 0 calc(var(--bs-gutter-x) * .5) !important;
	}
	.featuresCardRow{
		justify-content: center;
	}
	.featuresCardRow > div{
		margin-bottom: 30px;
	}
	.featuresColContent {
		padding: 30px 15px 260px 15px !important;
	}
	.featuresWrapper{
		padding-bottom: 20px !important;
	}
	.howItWorksWrapper{
		padding: 50px 0 30px 0 !important;
	}
	.howItWorksImgWrapper{
		display: none;
	}
	.howItWorksMainHeading {
		margin-bottom: 20px !important;
	}
	.faqsBg {
		padding-top: 40px !important;
		padding-bottom: 40px !important;
	}
	.faqsMainHeading {
		margin-bottom: 15px !important;
	}
	.accordion-item .accordion-button {
		font-size: 18px !important;
		line-height: 22px !important;
		padding-top: 15px !important;
		padding-bottom: 15px !important;
	}
	.faqsBg{
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.containerContact {
		padding-top: 50px !important;
		padding-bottom: 20px !important;
	}
	.contactText {
		padding: 0 0px 0 0px !important;
	}
	.contactHContainer {
		padding-bottom: 20px !important;
	}
	.contect-with-us{
		padding-top: 40px;
	}
	.footerContentWrapper{
		flex-wrap: wrap;
		justify-content: center;
	}
	.footer-logo{
		display: flex;
		width: 100%;
		justify-content: center;
		margin-bottom: 20px;
	}
	.footer-revised{
		text-align: center;
	}
	.footer-list {
		flex-wrap: wrap;
		gap: 12px !important;
	}
	.footer-list a {
		font-size: 15px !important;
		line-height: 15px !important;
	}
	textarea.input-field{
		height: 100px !important;
	}
}

@media (max-width: 768px) {

	.main-heading{
		line-height: 50px !important;
		font-size: 35px !important;
	}
	.banner-sub-heading {
		font-size: 28px !important;
		line-height: 35px !important;
	}
	.appPlatforms{
		flex-wrap: wrap;
	}
	.appPlatforms svg{
		width: auto;
		height: 40px;

	}
	.howitWorksPointTitle {
		line-height: 35px !important;
		font-size: 24px !important;
	}
	.howitWorksPoint {
		padding-left: 70px !important;
	}
	.followHeading{
		line-height: 40px !important;
  		font-size: 20px !important;
	}
}

@media (max-width: 767px) {
	.bannerCategories {
		gap: 5px !important;
	}
	.bannerCategories .bannerCategoryThumb {
		gap: 0px !important;
		width: 92px !important;
		height: 80px !important;
	}
	.featuresColContent {
		padding: 30px 15px 330px 15px !important;
		text-align: center;
	}
	.featuresColContent *{
		text-align: center;
		justify-content: center;
	}
	.featuresColWrap:first-child .featuresColContent img{
		float: left;
	}
	.featuresMainHeading{
		justify-content: center;
	}
	
}

@media (max-width: 576px) {

	.nav-bar button,
	.about-social-link {
		display: none !important;
	}

	.main-heading {
		line-height: 40px !important;
		font-size: 32px !important;
	}
	.banner-sub-heading {
		font-size: 25px !important;
		line-height: 32px !important;
	}
	p{
		line-height: 23px !important;
		font-size: 14px !important;
	}
	p.aboutHglText{
		line-height: 25px !important;
		font-size: 16px !important;
		padding-top: 5px !important;
	}
	.howitWorksPointTitle {
		line-height: 31px !important;
		font-size: 21px !important;
	}
	.followHeading{
		line-height: 40px !important;
  		font-size: 20px !important;
	}
}

@media (max-width: 480px) {
  
	.change-Btn {
		font-size: 32px !important;
		line-height: 38px !important;
		height: 40px !important;
		padding: 0px 10px !important;
	}


}

@media (max-width: 420px) {
	.change-Btn {
		font-size: 33px;
		line-height: 42px;
		height: 42px;
		padding: 0px 7px;
	}
	.logo svg {
		width: 180px !important;
	}
	.appPlatforms svg {
		height: 35px;
	}
	.featuresColContent {
		padding: 15px 10px 280px 10px !important;
	}
	.howitWorksPoint .howitWorksPointCount {
		font-size: 20px !important;
		width: 40px !important;
		height: 40px !important;
		line-height: 36px !important;
	}
	.howitWorksPoint .howitWorksPointCount::before {
		width: 52px !important;
		height: 52px !important;
	}
	.howitWorksPoint::after {
		left: 20px !important;
	}
	.howitWorksPoint::before {
		left: 20px !important;
	}
	.howitWorksPoint {
		padding-left: 55px !important;
	}
	.showToggle {
		top: 61px;
	}
}

@media (max-width: 350px) {
	.appPlatforms svg {
		height: 30px;
	}
	.featuresColContent {
		padding: 15px 10px 250px 10px !important;
	}
	.logo svg {
		width: 150px !important;
	}
}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {

  


}

@media (max-width: 1199px) {

  
}

@media (max-width: 991px) {
	.rtl{
		.nav-bar .nav-bar-list a {
			line-height: 30px;
		}
		// .accordion-item .accordion-button {
		// 	font-size: 30px !important;
		// 	line-height: 30px !important;
		// }
	}
}

@media (max-width: 768px) {

	.rtl{
		.main-heading {
			line-height: 40px !important;
			font-size: 60px !important;
		}
		.banner-sub-heading {
			font-size: 45px !important;
			line-height: 35px !important;
		}
		.featuresTitleHeading {
			line-height: 30px !important;
			font-size: 40px !important;
		}
		.howitWorksPointTitle {
			line-height: 30px !important;
			font-size: 40px !important;
		}
		.howitWorksPoint {
			padding-right: 70px !important;
		}
		
	}

}
@media (max-width: 767px) {

	.rtl{
		.featuresColWrap:first-child .featuresColContent img {
			float: right;
		}
		
		.accordion-item .accordion-button {
			font-size: 34px !important;
			line-height: 25px !important;
		}
		.accordion-body p{
			line-height: 27px;
  			font-size: 25px;
		}
		.followHeading{
			font-size: 34px !important;
			line-height: 25px !important;
		}
	}

}

@media (max-width: 576px) {
	.rtl{
		.main-heading {
			line-height: 37px !important;
			font-size: 50px !important;
		}
		.banner-sub-heading {
			font-size: 40px !important;
			line-height: 30px !important;
		}
		p{
			line-height: 28px !important;
			font-size: 28px !important;
		}
		.aboutHglText {
			font-size: 35px !important;
		}
		.featuresTitleHeading {
			line-height: 30px !important;
			font-size: 37px !important;
		}
		.howitWorksPointTitle {
			line-height: 30px !important;
			font-size: 37px !important;
		}
		.followHeading{
			font-size: 34px !important;
			line-height: 35px !important;
		}
		
	}
}

@media (max-width: 480px) {
	.rtl{
		.change-Btn {
			font-size: 16px !important;
			line-height: 44px !important;
		}
	}
	

}

@media (max-width: 420px) {
	.rtl{
		.howitWorksPoint {
			padding-right: 55px !important;
		}
		.howitWorksPoint::after {
			right: 20px !important;
		}
		.howitWorksPoint::before {
			right: 20px !important;
		}
	}
}