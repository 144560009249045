

@font-face {
  font-family: 'Raleway bold';
  src: url('../fonts/Raleway-Bold.woff2') format('woff2'),
      url('../fonts/Raleway-Bold.woff') format('woff'),
      url('../fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway medium';
  src: url('../fonts/Raleway-Medium.woff2') format('woff2'),
      url('../fonts/Raleway-Medium.woff') format('woff'),
      url('../fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway extraBold';
  src: url('../fonts/Raleway-ExtraBold.woff2') format('woff2'),
      url('../fonts/Raleway-ExtraBold.woff') format('woff'),
      url('../fonts/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway regular';
  src: url('../fonts/Raleway-Regular.woff2') format('woff2'),
      url('../fonts/Raleway-Regular.woff') format('woff'),
      url('../fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway semiBold';
  src: url('../fonts/Raleway-SemiBold.woff2') format('woff2'),
      url('../fonts/Raleway-SemiBold.woff') format('woff'),
      url('../fonts/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinsmedium";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff"),
    url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppinsregular";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/****************Arabic Fonts***************/

@font-face {
  font-family: 'Almadinah1';
  src: url('../fonts/arabic/Almadinah-Light.woff2') format('woff2'),
      url('../fonts/arabic/Almadinah-Light.woff') format('woff'),
      url('../fonts/arabic/Almadinah-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Almadinah2';
  src: url('../fonts/arabic/Almadinah.woff2') format('woff2'),
      url('../fonts/arabic/Almadinah.woff') format('woff'),
      url('../fonts/arabic/Almadinah.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Almadinah3';
  src: url('../fonts/arabic/Almadinah-Bold.woff2') format('woff2'),
      url('../fonts/arabic/Almadinah-Bold.woff') format('woff'),
      url('../fonts/arabic/Almadinah-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



:root {

  --regular: "Raleway regular";
  --medium: "Raleway medium";
  --semiBold: "Raleway semiBold";
  --bold: "Raleway bold";
  --extraBold: "Raleway extraBold";
  --popRegular: "poppinsregular";
  --popMedium: "poppinsmedium";
 

  --arLight: "Almadinah1";
  --arRegular: "Almadinah2";
  --arBold: "Almadinah3";
}