:root {
    --themeColor: #02755A;
    --black: #000000;
    --grey: #B8C6CC;
    --grey2: #9ca7ac;
    --grey3: #dbe7ec;
    --white: #FFFFFF;
    --lightYellow: #FFFDEB;
    --dark: #142535;
    --dark2: #2B4761;
    --darkBg: #1A3248;
    --lightGreen2: #90C5B9;
    --brown: #4C1F0C;
    --hoverBg: #0b8d6e;
    --lightGreen: #0D8B6D;
    --gold: #bd8b27;
    --gold2: #fdd37e;
    --red: #dc0000;
    --green: #69b935;
    --sRed: #ffe0e0;
    --accord-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --accord-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    
    --goldGradient: linear-gradient(180deg, rgba(251, 238, 171, 1) 0%, rgba(172, 130, 46,1) 100%);
    --themeGradientAr: linear-gradient(90deg, rgba(13, 139, 109, .5) 0%, rgba(13, 139, 109, 1) 100%);
    --themeGradientEn: linear-gradient(90deg, rgba(13, 139, 109, 1) 0%, rgba(13, 139, 109, .5) 100%);
}